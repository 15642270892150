<template>
  <div>
    <!--<vulnerability-detail
      :vulnsData="vulnerabilitiesData"
      :showVulnsDetailDialog.sync="showVulnsDetailDialog"
    />-->
    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="localItems"
        :items-per-page="5"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-rocket-launch-outline
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.title="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold pointer primary--text text--lighten-2"
              @click="fullViewItem(item)"
            >
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              @click="fullViewItem(item)"
            >
              Started on: {{ item.startDate }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template v-slot:item.data="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
            >
              {{ item.data.numCritical }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:item.data="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
            >
              {{ item.data.numHigh }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:item.data="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
            >
              {{ item.data.numModerate }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:item.data="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
            >
              {{ item.data.numLow }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:item.status="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'pending' || item.status === 'Created'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color='primary'>mdi-creation-outline</v-icon>
              </v-avatar>
            </template>
            <span>Created</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'scheduled' || item.status === 'Ready to Start'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="warning">mdi-timer-play-outline</v-icon>
              </v-avatar>
            </template>
            <span>Ready to Start</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'in progress' || item.status === 'Testing'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="warning">mdi-test-tube</v-icon>
              </v-avatar>
            </template>
            <span>Testing</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'in redaction' || item.status === 'Reporting'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="purple">mdi-feather</v-icon>
              </v-avatar>
            </template>
            <span>Reporting</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'Report Ready'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="purple">mdi-file-document-check-outline</v-icon>
              </v-avatar>
            </template>
            <span>Report Ready</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'Report Sent'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="purple">mdi-file-send-outline</v-icon>
              </v-avatar>
            </template>
            <span>Report Sent</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.status === 'completed' || item.status === 'Done'"
                color="grey"
                class="my-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-check</v-icon>
              </v-avatar>
            </template>
            <span>Done</span>
          </v-tooltip>
        </template>

        <template v-slot:item.items="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary lighten-2"
                class=""
                icon
                large
                v-bind="attrs"
                v-on="on"
                :disabled="item.category === 'none'"
                @click="fullViewItem(item)"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import BlueCard from '../base/BlueCard.vue'
  import PentestService from '@/services/pentest.service'

  export default {
    components: {
      BlueCard,
    },
    props: {
      items: {
        required: true,
        type: Array,
      },
    },
    data: () => ({
      editedIndex: 1,
      editedItem: {},
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'icon',
          width: '20px',
          class: 'text-center font-weight-medium subtitle-1',
        },
        {
          text: 'Title',
          value: 'title',
          class: 'text-left font-weight-medium subtitle-1',
        },
        {
          text: 'Critical',
          value: 'data',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1',
        },
        {
          text: 'High',
          value: 'data',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1',
        },
        {
          text: 'Moderate',
          value: 'data',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1',
        },
        {
          text: 'Low',
          value: 'data',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
          class: 'text-center font-weight-medium subtitle-1',
        },
        {
          text: 'Actions',
          value: 'items',
          sortable: false,
          align: 'center',
          width: '180px',
          class: 'text-center font-weight-medium subtitle-1',
        },
      ],
    }),
    computed: {
      localItems () {
        return this.items
      },
    },
    methods: {
      async fullViewItem (item) {
        this.editedIndex = this.localItems.indexOf(item)
        window.location = (`/pentest/detail/${item.id}`)
      },
    },
  }
</script>
