<template>
  <div>
    <base-dialog
      :show="showFileDialog"
      :form-title="isSuperUser ? 'Upload Report/File' : 'Upload File'"
      icon="mdi-upload"
      text-btn2="Upload"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col
            v-if="isSuperUser"
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">FILE CATEGORY</div>
            <v-checkbox
              v-model="UploadType"
              class="mt-0"
              color="secondary"
              label="Report"
            />
          </v-col>
          <v-col
            v-if="isSuperUser && UploadType"
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              TYPE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-radio-group
              v-model="type"
              class="mt-0"
              :disabled="!UploadType"
            >
              <v-radio
                label="Detailed [Report]"
                value="report_detailed"
              />
              <v-radio
                label="Summary [Report]"
                value="report_summary"
              />
              <v-radio
                label="Attestation"
                value="attestation"
              />
              <v-radio
                label="Notification"
                value="notification"
              />
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              FILE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-file-input
              v-model="file"
              :rules="[v => !!v || 'This field is required']"
              truncate-length="15"
              chips
              counter
              outlined
              show-size
              dense
              @change="uploadFile"
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import snackbarPlugin from '@/plugins/snackbar'
import FilesService from '@/services/files.service.js'
import UserService from '@/services/user.service'

export default {
  components: {
    BaseDialog
  },
  props: ['showFileDialog'],
  data() {
    return {
      file: null,
      fileId: null,
      valid: true,
      type: null,
      UploadType: false,
      isSuperUser: false,
      isUpload: false
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
  },
  methods: {
    async uploadFile() {
      const res = await FilesService.postUploadFile([this.file])
      snackbarPlugin.showSuccess(`File ${this.file.name} uploaded!`)
      this.fileId = res.uuid
      if (res.res.status === 204) {
        this.isUpload = true
      }
    },
    async close(state) {
      if (!this.isUpload && state === true) {
        this.fileId = null
        this.file = null
        this.isUpload = false
      }
      if (this.isUpload && state !== true) {
        await FilesService.deleteFileUploaded(this.fileId)
        this.fileId = null
        this.file = null
        this.isUpload = false
      } else {
        this.fileId = null
        this.file = null
        this.isUpload = false
      }
      this.$emit('update:show-file-dialog', false)
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.UploadType) {
          snackbarPlugin.showSuccess(`Report ${this.file.name} uploaded!`)
          await FilesService.postReport({
            type: this.type,
            projectId: this.$route.params.id,
            fileId: this.fileId
          }).then(() => {
            this.$emit('reload-items')
          })
          this.close(true)
        } else {
          snackbarPlugin.showSuccess(`File ${this.file.name} uploaded!`)
          await FilesService.postAddFileByPentest(
            this.$route.params.id, this.fileId
          ).then(() => {
            this.$emit('reload-items')
          })
          this.close(true)
        }
      } else {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
