<template>
  <div>
    <base-dialog
      :show="showDownloadDialog"
      formTitle="Download file"
      size="500px"
      icon="mdi-download"
      textBtn2="Download"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <div ref="form">
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <v-icon
                v-if="items.type === 'application/docx'"
                x-large
                class="my-2"
              >
                mdi-file-word-box
              </v-icon>
              <v-icon
                v-if="items.type === 'application/pdf'"
                x-large
                class="my-2"
              >
                mdi-file-pdf-box
              </v-icon>
              <v-icon
                v-else-if="items.type === 'application/jpg' || items.type=== 'application/jpeg'"
                x-large
                class="my-2"
              >
                mdi-file-jpg-box
              </v-icon>
              <v-icon
                v-else-if="items.type === 'application/png'"
                x-large
                class="my-2"
              >
                mdi-file-png-box
              </v-icon>
              <v-icon
                v-else-if="items.type === 'application/csv'"
                x-large
                class="my-2"
              >
                mdi-file-table-outline
              </v-icon>
              <v-icon
                v-else-if="items.type === 'application/exe'"
                x-large
                class="my-2"
              >
                mdi-file-cog
              </v-icon>
              <v-icon
                v-else-if="items.type === 'application/zip'"
                x-large
                class="my-2"
              >
                mdi-zip-box
              </v-icon>
              <v-icon
                v-else
                x-large
                class="my-2"
              >
                mdi-file-document-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h6 class="primary--text text--lighten-2">File name</h6>
              <div>{{ items.name }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h6 class="primary--text text--lighten-2">Uploaded at</h6>
              <div>{{ new Date(items.createdAt).toLocaleDateString("en-CA") }}</div>
            </v-col>
          </v-row>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
  import BaseDialog from '@/components/dialog/BaseDialog'
  import filesService from '@/services/files.service'
  import snackbarPlugin from '@/plugins/snackbar'

  export default {
    components: {
      BaseDialog,
    },
    props: {
      showDownloadDialog: false,
      items: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
      }
    },
    mounted () {
    },
    methods: {
      close () {
        this.$emit('update:show-download-dialog', false)
      },
      async save () {
        const res = await filesService.getDownloadFileById(this.items.id, this.items.name, this.items.type)
        this.close()
        if (res.status === 200) {
          snackbarPlugin.showSuccess(`File ${this.items.name} Downloaded!`)
          this.$router.push(`/pentest/detail/${this.$route.params.id}`)
        }
      },
    },
  }
</script>
