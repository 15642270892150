<template>
  <v-row>
    <delete-message-dialog
      :edited-item="editedItem"
      :show-delete-dialog.sync="showDeleteDialog"
      v-on:reload-items="$emit('reload-items')"
    />
    <v-col cols="12" class="d-flex align-center pb-0">
      <div class="pb-0 d-flex">
        <p class="mb-0">
          {{
            new Date(data.createdAt.slice(0, 16) + "+0000").toLocaleString(
              "en-US",
              {
                dateStyle: "medium",
                timeStyle: "short"
              }
            )
          }}
        </p>
      </div>
    </v-col>
    <v-col cols="12">
      <v-card flat class="px-4 mr-4" color="grey">
        <v-row v-if="data.type === 'user message'">
          <v-col cols="2" sm="2" md="1" lg="2" xl="1" class="mr-2 mr-xl-6">
            <v-avatar color="grey darken-1">
              <v-icon v-if="!data.isDeleted" color="primary">mdi-comment-text-outline</v-icon>
              <v-icon v-else color="error">mdi-comment-remove-outline</v-icon>
            </v-avatar>
          </v-col>
          <v-col>
            <v-row dense>
              <v-col cols="8">
                <h6 v-if="!data.isDeleted || showDeletedComment">
                  {{ data.uploadBy.fullName }}
                </h6>
                <div v-if="data.isDeleted && !showDeletedComment">
                  Message from {{ data.uploadBy.fullName }} was removed
                </div>
              </v-col>
              <v-spacer />
              <v-col
                v-if="isHavePermDel(data)"
                cols="1"
                class="mt-n2 mr-5 mr-sm-2 mr-md-1 mr-lg-6 mr-xl-2"
              >
                <v-btn color="error" text icon @click="deleteItem(data)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="isSuperUser && !showDeletedComment && data.isDeleted"
                cols="1"
                class="my-n2 mr-5 mr-sm-2 mr-md-1 mr-lg-6 mr-xl-2"
              >
                <v-btn color="primary" text icon @click="viewComment(data)">
                  <v-icon>mdi-comment-eye-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="isSuperUser && showDeletedComment && data.isDeleted"
                cols="3"
                md="2"
                class="my-n2 mr-5 mr-sm-2 mr-md-1 mr-lg-2"
              >
                <div class="error--text">Deleted</div>
              </v-col>
            </v-row>
            <v-row dense class="mt-n4">
              <v-col>
                <div v-if="data.uploadBy.clientId !== null">
                  {{ data.client.name }}
                </div>
                <div v-else>Vumetric</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="data.type === 'project status'">
          <v-col cols="2" sm="2" md="1" lg="2" xl="1" class="mr-2 mr-xl-6">
            <v-avatar color="grey darken-1">
              <v-icon v-if="data.message.includes('pending')" color="primary lighten-2">
                mdi-creation-outline
              </v-icon>
              <v-icon v-if="data.message.includes('Created')" color="primary lighten-2">
                mdi-creation-outline
              </v-icon>
              <v-icon v-if="data.message.includes('scheduled')" color="warning">
                mdi-timer-play-outline
              </v-icon>
              <v-icon v-if="data.message.includes('Ready to Start')" color="warning">
                mdi-timer-play-outline
              </v-icon>
              <v-icon
                v-if="data.message.includes('in progress')"
                color="warning"
              >
                mdi-test-tube
              </v-icon>
              <v-icon
                v-if="data.message.includes('Testing')"
                color="warning"
              >
                mdi-test-tube
              </v-icon>
              <v-icon
                v-if="data.message.includes('in redaction')"
                color="purple"
              >
                mdi-feather
              </v-icon>
              <v-icon
                v-if="data.message.includes('Reporting')"
                color="purple"
              >
                mdi-feather
              </v-icon>
              <v-icon
                v-if="data.message.includes('Report Ready')"
                color="purple"
              >
                mdi-file-document-check-outline
              </v-icon>
              <v-icon
                v-if="data.message.includes('Report Sent')"
                color="purple"
              >
                mdi-file-send-outline
              </v-icon>
              <v-icon v-if="data.message.includes('completed')" color="success">
                mdi-check
              </v-icon>
              <v-icon v-if="data.message.includes('Done')" color="success">
                mdi-check
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col>
            <v-row dense>
              <v-col>
                <h6>New Pentest Status</h6>
              </v-col>
            </v-row>
            <v-row dense class="mt-n4">
              <v-col>
                <div v-if="data.message.includes('pending')">
                  Pentest Created
                </div>
                <div v-if="data.message.includes('Created')">
                  Pentest Created
                </div>
                <div v-if="data.message.includes('scheduled')">Ready to Start</div>
                <div v-if="data.message.includes('Ready to Start')">Ready to Start</div>
                <div v-if="data.message.includes('in progress')">Testing</div>
                <div v-if="data.message.includes('Testing')">Testing</div>
                <div v-if="data.message.includes('Reporting')">Reporting</div>
                <div v-if="data.message.includes('in redaction')">
                  Report Ready
                </div>
                <div v-if="data.message.includes('Report Ready')">
                  Report Ready
                </div>
                <div v-if="data.message.includes('Report Sent')">Report Sent</div>
                <div v-if="data.message.includes('completed')">Done</div>
                <div v-if="data.message.includes('Done')">Done</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="data.type === 'report upload'">
          <v-col cols="2" sm="2" md="1" lg="2" xl="1" class="mr-2 mr-xl-6">
            <v-avatar color="grey darken-1">
              <v-icon color="success">mdi-file-chart-outline</v-icon>
            </v-avatar>
          </v-col>
          <v-col>
            <v-row dense>
              <v-col>
                <h6>A new report is available</h6>
              </v-col>
            </v-row>
            <v-row dense class="mt-n4">
              <v-col>
                <div>{{ data.client.name }}</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="data.message && data.type === 'user message'">
          <v-col class="pt-0">
            <pre style="white-space: pre-wrap; background-color: transparent !important;" class="ml-1 mb-0">{{ data.message }}</pre>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DeleteMessageDialog from "@/components/dialog/activities/DeleteMessageModal.vue";
import PentestService from "@/services/pentest.service";
import UserService from '@/services/user.service'

export default {
  components: {
    DeleteMessageDialog
  },
  props: ["data", "isSuperUser", "isAdmin"],
  data: () => ({
    showDeleteDialog: false,
    editedItem: {},
    showDeletedComment: false
  }),
  methods: {
    isHavePermDel(item) {
      const profile = UserService.getLocalProfile()
      if (
        (item.uploadBy.id === profile.id && !item.isDeleted)
        || (
          profile.client !== null && item.uploadBy.clientId === profile.client.id
          && profile.role !== "Client User" && !item.isDeleted
        )
      ) {
        return true
      } else {
        return !!(this.isSuperUser && !item.isDeleted)
      }
    },
    deleteItem(data) {
      this.editedItem = data;
      this.showDeleteDialog = true;
    },
    async viewComment() {
      await PentestService.getByIdActivity(
        this.data.project.id,
        this.data.id
      ).then(res => {
        this.data.message = res.message;
        this.showDeletedComment = true;
      });
    }
  }
};
</script>
<style lang="scss" scoped>

pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>
