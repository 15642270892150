<template>
  <div>
    <base-dialog
      :show="showUpdateRetestDialog"
      form-title="Update Retest Status"
      icon="mdi-autorenew"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                TIMELINE <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-menu
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="items.startDate"
                  :rules="[v => !!v || 'This field is required']"
                  label="Retest start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="items.startDate"
                :min="minDate"
                @input="menuStartDate = false"
              />
            </v-menu>
            <v-menu
              v-model="menuEndDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="items.endDate"
                  label="Retest expected end date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="items.endDate"
                :min="minDate"
                @input="menuEndDate = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0" style="width: 100%">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                STATUS <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-select
              v-model="items.status"
              placeholder="Select a statu"
              :items="status"
              :rules="[v => !!v || 'This field is required']"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0" style="width: 100%">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                COMMENTS <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-textarea
              v-model="items.comments"
              placeholder="Enter a comments"
              :rules="[v => !!v || 'This field is required']"
              clearable
              outlined
              no-resize
              dense
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import PentestService from '@/services/pentest.service'
import snackbarPlugin from '@/plugins/snackbar'
import { status } from '@/data/common'

export default {
  components: {
    BaseDialog
  },
  props: {
    showUpdateRetestDialog: false,
    items: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      status,
      valid: true,
      menuStartDate: false,
      menuEndDate: false
    }
  },
  computed: {
    minDate() {
      const today = new Date()
      return this.formatDate(today)
    }
  },
  methods: {
    formatDate(date) {
      let month = `${date.getMonth() + 1}`
      let day = `${date.getDate()}`
      const year = date.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    close() {
      this.$emit('update:show-update-retest-dialog', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        PentestService.putRetest(this.$route.params.id, this.items.id, {
          startDate: this.items.startDate,
          endDate: this.items.endDate,
          status: this.items.status,
          comments: this.items.comments
        }).then(() => {
          this.$emit('reload-items')
          snackbarPlugin.showSuccess('Retest updated!')
        })
        this.close()
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
