import api from './api'

class CommunicationsService {
  async postEmail(data) {
    const response = await api.post('/api/v1/communications/', data)
    return response
  }

  async sendReportCommunication(data) {
    const response = await api.post('/api/v1/communications/transfer', data)
    return response
  }
}

export default new CommunicationsService()
