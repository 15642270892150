<template>
  <span>
    <v-skeleton-loader v-if="loading" type="list-item-avatar-three-line, list-item-three-line, list-item-three-line" />
    <span v-if="!loading">
      <v-card v-if="items !== null && items.riskScore !== null" class="pa-5">
        <div class="d-flex">
          <div class="mr-2">
            <v-card
              class="rounded-b-0 px-8 py-4"
              flat
              :color="cyberRiskGrade[items.riskScore] ? cyberRiskGrade[items.riskScore].color : 'null'"
            >
              <h1 class="mb-0 text-white" style="font-size: 54px">{{ items.riskScore ? items.riskScore : 'N' }}</h1>
            </v-card>
            <v-card flat color="grey" class="text-center rounded-t-0 py-1 font-weight-bold">
              {{ cyberRiskGrade[items.riskScore] ? cyberRiskGrade[items.riskScore].title[lang] : 'N/A' }}
            </v-card>
          </div>
          <v-card flat class="px-4" color="d-flex flex-grow-1 mt-">
            <v-row>
              <v-col class="py-0">
                <div class="d-flex align-center">
                  <v-icon size="30" color="red">mdi-alert-outline</v-icon>
                  <h6 class="mb-0 ml-2 red--text" color="primary">Top {{ items.top5Risks.length > 2 ?
                    items.top5Risks.length : '' }} {{ lang === 'en' ? 'Cyber Risks' : 'Risques Cybersécurités' }}</h6>
                </div>
                <ul v-if="items" class="ulIR liIR">
                  <li class="ml-7 liIR" v-for="(item, i) in items.top5Risks" :key="i">{{ item }}</li>
                </ul>
                <p v-if="items.top5Risks.length === 0" class="ml-12" style="font-size: 18px;">{{ lang === 'en' ? 'To be discussed' : 'À discuter' }}</p>
              </v-col>
              <v-col class="py-0">
                <div class="d-flex align-center">
                  <v-icon size="30" color="success">mdi-check-circle-outline</v-icon>
                  <h6 class="mb-0 ml-2 success--text">{{ lang === 'en' ? 'Improvement Roadmap' : "Plan d'Amélioration" }}</h6>
                </div>
                <ul v-if="items" class="ulIR liIR">
                  <li class="ml-7 liIR" v-for="(item, i) in items.improvementRoadmaps" :key="i">{{ item }}</li>
                </ul>
                <p v-if="items.improvementRoadmaps.length === 0" class="ml-12" style="font-size: 18px;">
                  {{ lang === 'en' ? 'To be discussed' : 'À discuter' }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <v-row class="mx-0 pt-3">
          <v-col class="px-0">
            <div class="d-flex align-center">
              <v-icon size="30" color="success">mdi-check-circle-outline</v-icon>
              <h6 class="mb-0 ml-2">{{ lang === 'en' ? 'Scope and Objective' : 'Portée et Objectif' }}</h6>
            </div>
            <div style="font-size: 16px;" class="ml-7 blue-list" v-html="compiledMarkdownSO" />
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col class="px-0">
            <div class="d-flex align-center">
              <v-icon size="30" color="success">mdi-check-circle-outline</v-icon>
              <h6 class="mb-0 ml-2">{{ lang === 'en' ? 'Methodology' : 'Méthodologie' }}</h6>
            </div>
            <div v-if="items" v-html="compiledMarkdownM" style="font-size: 16px;" class="ml-7 blue-list" />
          </v-col>
        </v-row>
      </v-card>
      <v-card v-else class="pa-2 d-flex align-center text-center" height="419px">
        <v-row>
          <v-col cols="12">
            <no-data />
          </v-col>
        </v-row>
      </v-card>
    </span>
  </span>
</template>

<script>
import { marked } from 'marked'

export default {
  components: {
    NoData: () => import('@/components/base/NoData.vue')
  },
  props: {
    items: {
      type: Object,
      default: null
    },
    phases: {
      required: true,
      type: Array
    },
    lang: {
      default: 'en',
      type: String
    }
  },
  data: () => ({
    loading: true,
    menu: false,
    currentTab: 0,
    selectedTypePhase: "external",
    cyberRiskGrade: {
      null: {
        color: 'primary',
        title: 'Null',
        info: 'Data not yet available'
      },
      A: {
        color: '#7bbe45',
        title: { en: 'Very Low', fr: 'Très Faible' },
        info: 'Reported findings do not pose any risk to your assets and a very low likelihood of being compromised.'
      },
      B: {
        color: '#619735',
        title: { en: 'Low', fr: 'Faible' },
        info: 'Reported findings do not pose any risk to your assets and a very low likelihood of being compromised.'
      },
      C: {
        color: '#fb871e',
        title: { en: 'Moderate', fr: 'Modéré' },
        info: 'Reported findings may lead to a compromise of your assets or disclosure of sensitive information but may require a significant number of resources.'
      },
      D: {
        color: '#e26310',
        title: { en: 'High', fr: 'Élevé' },
        info: 'Reported findings may lead directly to the compromise of assets with minimal efforts.'
      },
      F: {
        color: '#d60000',
        title: { en: 'Critical', fr: 'Critique' },
        info: 'Reported findings require minimal skill and result in high-privileged access to the affected assets.'
      }
    }
  }),
  computed: {
    compiledMarkdownSO() {
      return marked(
        this.items.scopeObjective ? this.items.scopeObjective : '',
        { sanitize: true, mangle: false, headerIds: false, silent: true }
      )
    },
    compiledMarkdownM() {
      return marked(
        this.items.methodology ? this.items.methodology : '',
        { sanitize: true, mangle: false, headerIds: false, silent: true }
      )
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      if (this.phases.length !== 0) {
        this.selectedTypePhase = this.phases[0]
      }
    }, 300)
  }
}
</script>
<style lang="scss">
.riskLabelShape {
  max-width: 32px !important;
  transform: rotate(90deg);
}

.riskRating {
  position: relative;
  left: 2.0rem;
  top: 0.70rem;
  font-size: 2.75rem;
}

.riskRating2 {
  position: relative;
  left: 1.65rem;
  top: 0.30rem;
  font-size: 1.75rem;
}

.riskRating3 {
  position: relative;
  left: 1.65rem;
  top: 0.28rem;
  font-size: 1.75rem;
}

.wrapText {
  white-space: initial;
}

.ulIR {
  list-style: none;
}

.ulIR .liIR::before {
  content: "\25A0";
  color: #0070C0;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.blue-list ul li {
  list-style: none;
}

.blue-list ul li::before {
  content: "■";
    color: #0070C0;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

</style>
