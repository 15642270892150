<template>
  <div>
    <vulnerability-detail
      :vulns-data="vulnerabilitiesData"
      :show-vulns-detail-dialog.sync="showVulnsDetailDialog"
      :language="language"
    />
    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="localItems"
        :items-per-page="5"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-bug-outline
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.title="{ item }">
          <v-list-item-content
            class="cursor-pointer"
            @click="fullViewItem(item)"
          >
            <v-list-item-title
              class="font-weight-bold resizeTextRST cursor-pointer"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:item.category="{ item }">
          <v-chip
            color="primary lighten-2"
            outlined
            class="text-capitalize"
          >
            {{ item.category }}
          </v-chip>
        </template>

        <template v-slot:item.risk="{ item }">
          <risk-level :risk="item.risk" :lang="language" />
        </template>

        <template v-slot:item.items="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary lighten-2"
                class=""
                icon
                large
                v-bind="attrs"
                v-on="on"
                @click="fullViewItem(item)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import BlueCard from '../base/BlueCard.vue'
import VulnerabilityDetail from '@/components/dialog/pentests/VulnerabilityDetail.vue'
import RiskLevel from '@/components/base/RiskLevel.vue'

export default {
  components: {
    BlueCard,
    VulnerabilityDetail,
    RiskLevel
  },
  props: {
    items: {
      required: true,
      type: Array
    },
    language: {
      default: 'en',
      type: String
    }
  },
  data: () => ({
    editedIndex: 1,
    editedItem: {},
    vulnerabilitiesData: {},
    showVulnsDetailDialog: false,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'icon',
        width: '20px',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Title',
        value: 'title',
        class: 'text-left font-weight-medium subtitle-1'
      },
      {
        text: 'Category',
        value: 'category',
        align: 'center',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Risk level',
        value: 'risk',
        align: 'center',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Actions',
        value: 'items',
        sortable: false,
        align: 'center',
        width: '180px',
        class: 'text-center font-weight-medium subtitle-1'
      }
    ]
  }),
  computed: {
    localItems() {
      return this.items
    }
  },
  methods: {
    fullViewItem(item) {
      this.editedIndex = this.localItems.indexOf(item)
      this.vulnerabilitiesData = item
      this.showVulnsDetailDialog = true
    }
  }
}
</script>

<style lang="scss">
.resizeTextRST{
  word-break: break-word;
  inline-size: 210px;
}
</style>
