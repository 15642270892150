<template>
  <div class="px-1">
    <download-file-dialog
      :show-download-dialog.sync="showDownloadDialog"
      :items="fileDataD"
    />

    <!--<restest-dialog
      :showRetestDialog.sync="showRetestDialog"
      isSecMethods="true" v-on:reload-items="initializeData"
    />-->

    <!--<update-retest-dialod
      v-if="retestData.length >= 1"
      :showUpdateRetestDialog.sync="showUpdateRetestDialog"
      :items="lastRetestData"
      v-on:reload-items="initializeData"
    />-->

    <edit-pentest-dialog
      v-if="showEPD"
      :progre-status="isEditablePentest()"
      :show-edit-dialog.sync="showEditDialog"
      :items="pentestData"
      v-on:reload-items="initializeData"
    />

    <upload-file-dialog
      :show-file-dialog.sync="showFileDialog"
      v-on:reload-items="initializeData"
    />

    <v-row>
      <v-col cols="12" class="d-flex pb-0 mt-0 mb-2">
        <v-spacer />
        <div v-if="isSuperUser || isAdmin" class="pr-1">
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                outlined
                color="primary"
                depressed
                v-bind="attrs"
                v-on="on"
                :disabled="!retestData"
                @click="showUpdateRetestDialog = true"
              >
                <v-icon class="mr-1">mdi-autorenew</v-icon>

                Update Retest
              </v-btn>
            </template>
            <span>Update Retest Status</span>
          </v-tooltip> -->
          <v-btn
            v-if="isSuperUser && pentestData && !pentestData.isSynced"
            class="mr-2"
            color="primary lighten-2"
            depressed
            outlined
            @click="syncWithMonday"
          >
            <v-icon class="mr-sm-2">mdi-sync</v-icon>
            <span class="d-none d-sm-block">Sync with Monday</span>
          </v-btn>
          <v-btn
            v-if="isSuperUser && pentestData && pentestData.isSynced"
            class="mr-2"
            color="primary lighten-2"
            depressed
            outlined
            disabled
            @click="syncWithMonday"
          >
            <v-icon class="mr-sm-2">mdi-sync</v-icon>
            <span class="d-none d-sm-block">Synced</span>
          </v-btn>
          <v-btn
            color="primary lighten-2"
            depressed
            outlined
            @click="showEditDialog = true"
          >
            <v-icon class="mr-sm-2">mdi-pencil</v-icon>
            <span class="d-none d-sm-block">EDIT</span>
          </v-btn>
          <v-btn
            v-if="isSuperUser"
            class="ml-2"
            color="primary lighten-2"
            depressed
            outlined
            @click="sendEmail"
          >
            <v-icon class="mr-sm-2">mdi-email-plus</v-icon>
            <span class="d-none d-sm-flex">Primary Contact</span>
          </v-btn>
          <v-btn
            color="primary lighten-2"
            class="ml-2"
            outlined
            depressed
            @click="showFileDialog = true"
          >
            <v-icon class="mr-sm-1">
              mdi-file-upload-outline
            </v-icon>
            <span class="d-none d-sm-flex">Upload</span>
          </v-btn>
        </div>
        <div class="ml-auto" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat class="rounded">
          <v-row class="d-flex justify-space-between px-2" no-gutter>
            <v-col
              v-for="(item, i) in progressBarStatus"
              :key="i"
              cols=""
              class="pa-0 d-flex align-center"
            >
              <item-progression
                :icon="item.icon"
                :text="item.text"
                :is-check="setPentestStatus(pentestData.status).num"
                :position="i + 1"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8">
        <!-- Overview -->
        <base-blue-card title="Project Overview" icon="rocket-launch-outline">
          <v-row class="mx-2 mt-4">
            <v-col cols="12" sm="6" lg="3" class="pa-0">
              <h6 class="mb-0">
                <v-icon class="mr-2" color="primary">mdi-tag-multiple-outline</v-icon>
                {{ pentestData.client ? pentestData.client.name : "N/A" }}
              </h6>
              <p class="ml-9">{{ pentestData.title }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-0">
              <h6 class="mb-0">
                <v-icon class="mr-2" color="primary">mdi-apps</v-icon> Phases
              </h6>
              <span class="ml-9">
                <v-chip label color="grey">{{
                  phaseNames.length !== 0
                    ? setPhaseType(phaseNames[0])
                    : setPentestType()
                }}</v-chip>
                <v-tooltip
                  v-if="phaseNames.length > 1 ? phaseNames.length : false > 1"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <span
                    v-for="(phase, index) in phaseNames.filter((phase, i) => i !== 0)"
                    :key="index"
                  >
                    <div>{{ setPhaseType(phase) }}</div>
                  </span>
                </v-tooltip>
              </span>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-0">
              <h6 class="mb-0">
                <v-icon class="mr-2" color="primary">mdi-package-variant</v-icon>
                Targeted Asset
              </h6>
              <p class="ml-9">{{ assetData ? assetData.name : "N/A" }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-0">
              <h6 class="mb-0">
                <v-icon class="mr-2" color="primary">mdi-clock-outline</v-icon>
                Timeline
              </h6>
              <p class="ml-9 pb-0 mb-0">
                Start on:
                {{ pentestData.startDate ? pentestData.startDate : "N/A" }}
              </p>
              <p class="ml-9">
                End on: {{ pentestData.endDate ? pentestData.endDate : "N/A" }}
              </p>
            </v-col>
            <!-- <v-col cols="12" md="3" class="pa-0">
              <h6 class="mb-0">
                <v-icon class="mr-2" color="primary">mdi-card-account-details-outline</v-icon> PRIMARY CONTACT
              </h6>
              <p class="ml-9" v-if="awaitToShow">
                <span>{{ pentestData.users[0] ? pentestData.users[0].fullName : 'N/A' }}</span>
              </p>
            </v-col> -->
          </v-row>
        </base-blue-card>
        <v-row>
          <v-col cols="12">
            <v-row class="mb-0">
              <v-col cols="auto">
                <v-tabs
                  class="mt-n3"
                  v-model="currentTab"
                  color="primary lighten-2"
                  background-color="transparent"
                >
                  <v-tab>EXECUTIVE SUMMARY</v-tab>
                  <v-tab>VULNERABILITIES
                    <!--<v-chip v-if="vulnerabilitiesData.length >= 1" label class="ml-2">
                      {{ vulnerabilitiesData.length }}
                    </v-chip>-->
                  </v-tab>
                  <v-tab>
                    PREVIOUS TESTS
                    <v-chip v-if="RelatedData.length >= 1" label class="ml-2">{{ RelatedData.length }}</v-chip>
                  </v-tab>
                  <v-tab>
                    DOCUMENTS
                    <v-chip v-if="filesData.length >= 1" label class="ml-2">{{ filesData.length }}</v-chip>
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-n0 pt-0">
                <v-tabs-items class="background" v-model="currentTab">
                  <v-tab-item>
                    <executive-summary
                      v-if="isShowExSu"
                      :items="executiveSummaryData"
                      :phases="phaseNames"
                      :lang="pentestData.language"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-title v-if="phasesData.length > 1" class="pb-0">
                        <h3 class="title font-weight-regular mb-0">Phases</h3>
                      </v-card-title>
                      <v-row>
                        <v-col cols="12" class="mt-0 pt-0">
                          <v-tabs v-if="phasesData.length > 1" v-model="currentTabPhase">
                            <v-tab v-for="(phase, index) in phasesData" :key="index" @click="selectedTypePhase = phase.name">
                              {{ phaseTabText(phase.name) }}
                            </v-tab>
                          </v-tabs>
                          <vulnerability-table
                            v-if="showV"
                            :items="getPhaseVulnerabilityData()"
                            :language="pentestData.language"
                            :view-vulnerability="displayVulnerability"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-col cols="12" class="px-0 mt-0 pt-0">
                      <prev-pentest-table
                        :items="RelatedData"
                        :vuln-index.sync="vulnIndex"
                      />
                    </v-col>
                  </v-tab-item>
                  <v-tab-item>
                    <v-col cols="12" class="px-0 mt-0 pt-0">
                      <report-table
                        v-if="showFD"
                        :items="filesData"
                        v-on:reload-items="initializeFileData"
                      />
                    </v-col>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="4">
        <base-blue-card
          id="chart"
          icon="chart-box-outline"
          :title="`Vulnerabilities ${ currentTab === 1 ? setPhaseType(selectedTypePhase) : '' }`"
          style="max-height:279px"
          class="mb-6"
        >
          <h5 class="text-right mt-n10 mr-3">
            <v-chip class="" label color="primary">
              {{ setSerieVulnsData()[0].data.reduce((a, b) => a + b, 0) }} Vulns
            </v-chip>
          </h5>
          <v-row>
            <v-col class="py-0">
              <apexchart
                v-if="countRiskData.numCritical + countRiskData.numHigh + countRiskData.numModerate + countRiskData.numLow"
                type="bar"
                class="d-flex justify-center"
                :options="chartOptionsVulns"
                :series="setSerieVulnsData()"
                height="190"
              />
              <div
                v-else
                class="d-flex justify-center align-center"
                style="height:264px"
              >
                <no-data />
              </div>
            </v-col>
          </v-row>
        </base-blue-card>
        <base-blue-card icon="forum-outline" title="Messages" class="mt-2">
          <template v-slot:action>
            <v-tooltip v-if="isAdmin || isSuperUser" class="d-lg-none" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary lighten-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="isShowMsg = !isShowMsg"
                >
                  <v-icon>{{ isShowMsg ? 'mdi-message-minus-outline' : 'mdi-message-plus-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ isShowMsg ? 'Close' : 'Write Message' }}</span>
            </v-tooltip>
          </template>
          <v-row>
            <v-col>
              <v-card
                v-if="isAdmin && isShowMsg"
                elevation="0"
                color="grey"
                class="py-2 px-2 mb-4"
              >
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-textarea
                    v-model="comment"
                    solo
                    flat
                    rows="3"
                    auto-grow
                    :rules="[v => !!v, v => (v || '').length <= 512]"
                    background-color="grey"
                    placeholder="Send a message... Question, Comment or Request"
                    min-height="100px"
                    no-resize
                    counter="512"
                    clearable
                  />
                </v-form>
                <v-btn
                  text
                  color="primary lighten-2"
                  class="text-right mt-n8 ml-3"
                  @click="sendComment"
                >
                  <v-icon class="mr-2">mdi-send</v-icon>
                  Send
                </v-btn>
              </v-card>
              <div
                class="overflow-y-auto overflow-x-hidden"
                style="max-height: 311px"
              >
                <v-skeleton-loader
                  v-if="skeletonLoaderActivities"
                  type="list-item-avatar,list-item, divider, list-item-avatar, list-item,divider, list-item-avatar,list-item"
                />
                <span v-for="(activity, i) in Activities" :key="i">
                  <message-box
                    v-if="!activity.isDeleted"
                    :data="activity"
                    :is-super-user="isSuperUser"
                    :is-admin="isAdmin"
                    v-on:reload-items="initializeData"
                  />
                </span>
                <v-card
                  v-if="Activities.length === 0"
                  flat
                  class="pa-2 text-center"
                >
                  <v-divider />
                  <div class="py-3">No data activity</div>
                  <v-divider />
                </v-card>
              </div>
            </v-col>
          </v-row>
        </base-blue-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VulnerabilityTable from "@/components/table/VulnerabilityTable"
import ReportTable from "@/components/table/ReportTable"
import PentestService from "@/services/pentest.service"
import AssetService from "@/services/asset.service"
import FilesService from "@/services/files.service.js"
import RestestDialog from "@/components/dialog/pentests/RetestDialog.vue"
import UpdateRetestDialod from "@/components/dialog/pentests/UpdateRetestDialog.vue"
import EditPentestDialog from "@/components/dialog/pentests/EditPentestDialog.vue"
import DownloadFileDialog from "@/components/dialog/pentests/DowloadFileDialog.vue"
import PrevPentestTable from "@/components/table/PrevPentestTable.vue"
import StatsCardVariant from "@/components/card/StatsCardVariant"
import MessageBox from "@/components/card/MessageBox.vue"
import ExecutiveSummary from "@/components/table/ExecutiveSummary.vue"
import snackbarPlugin from "@/plugins/snackbar"
import { pentestType, phasesOptions, allStatus, tagPhasesOptions } from "@/data/common"
import UploadFileDialog from "@/components/dialog/pentests/UploadFileDialog.vue"

export default {
  name: "PentestDetail",
  metaInfo: {
    title: "Pentest"
  },
  components: {
    VulnerabilityTable,
    ReportTable,
    RestestDialog,
    UpdateRetestDialod,
    EditPentestDialog,
    DownloadFileDialog,
    PrevPentestTable,
    StatsCardVariant,
    MessageBox,
    pentestType,
    ExecutiveSummary,
    UploadFileDialog,
    NoData: () => import("@/components/base/NoData.vue"),
    ItemProgression: () => import("@/components/base/itemProgression.vue")
  },
  data() {
    const pentestTabs = [
      "Pentest detail",
      "Vulnerability detail",
      "Pentest Files"
    ]
    return {
      isAdmin: false,
      isSuperUser: false,
      isShowMsg: false,

      showFileDialog: false,
      showRetestDialog: false,
      showUpdateRetestDialog: false,
      showEditDialog: false,
      showDownloadDialog: false,
      pentestDetaildialog: false,
      pentestType,
      phasesOptions,
      tagPhasesOptions,
      phaseNames: [],
      phasesData: [],
      allStatus,

      showFD: false,
      showV: false,
      showEPD: false,
      isShowExSu: false,
      awaitToShow: false,

      progreStatus: 0,
      pentestData: {},
      retestData: {},
      lastRetestData: {},
      assetData: null,
      filesData: {},
      vulnerabilitiesData: [],
      executiveSummaryData: [],
      countRiskData: {},
      isRetestable: true,
      assetIdF: {},
      RelatedData: [],

      downloadFileId: null,
      fileDataD: {},
      pentestTabs: pentestTabs,
      currentTab: pentestTabs.indexOf(this.$route.name),
      currentTabPhase: 0,
      vulnIndex: 0,
      Activities: {},
      comment: null,
      valid: true,

      skeletonLoaderActivities: true,

      progressColor: "",

      selectedTypePhase: "external",

      progressBarStatus: [
        { text: "Created", icon: "mdi-creation-outline", isCheck: 0 },
        { text: "Ready to Start", icon: "mdi-timer-play-outline", isCheck: 0 },
        { text: "Testing", icon: "mdi-test-tube", isCheck: 0 },
        { text: "Reporting", icon: "mdi-feather", isCheck: 0 },
        { text: "Delivered", icon: "mdi-check", isCheck: 0 }
      ],

      chartOptionsVulns: {
        noData: {
          text: "No data available",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: -50,
          style: {
            color: "grey",
            fontSize: "14px"
          }
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
            distributed: true,
            borderRadius: 5
          }
        },
        stroke: {
          show: false
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value
            }
          },
          floating: false,
          decimalsInFloat: 0
        },
        xaxis: {
          categories: ["CRITICAL", "HIGH", "MODERATE", "LOW"],
          labels: {
            style: {
              colors: ["#B71C1C", "#FF9800", "#FCCB65", "#4CAF50"],
              fontSize: "12px",
              fontWeight: "bold"
            }
          }
        },
        colors: ["#B71C1C", "#FF9800", "#FCCB65", "#4CAF50"]
      }
    }
  },
  mounted() {
    this.initializeData()
    this.isAdmin = JSON.parse(localStorage.profile).role !== "Client User"
    this.isSuperUser = JSON.parse(localStorage.profile).role === "Superuser"

    if (sessionStorage.nextAfter) {
      this.downloadFileId = this.$route.params.fileId
      this.downloadFileData(this.downloadFileId)
      this.showDownloadDialog = true
      sessionStorage.clear("nextAfter")
    }
  },
  computed: {
    getTypeText() {
      return this.pentestType.filter(
        title => title.value === this.pentestData.type
      )[0].text
    }
  },
  methods: {
    copyElem(elem) {
      navigator.clipboard.writeText(elem)
    },
    isEditablePentest() {
      return this.progreStatus === 1
    },
    setPhaseType(type) {
      for (let i = 0; i < this.phasesOptions.length; i++) {
        if (this.phasesOptions[i].value === type) {
          return this.phasesOptions[i].text
        }
      }
      for (let i = 0; i < this.tagPhasesOptions.length; i++) {
        if (this.tagPhasesOptions[i].value === type) {
          return this.tagPhasesOptions[i].text
        }
      }
      // handle non-hardcoded tag phase
      return type
    },
    setPentestType() {
      for (let i = 0; i < this.pentestType.length; i++) {
        if (this.pentestType[i].value === this.pentestData.type) {
          return this.pentestType[i].text
        }
      }
    },
    setSerieVulnsData() {
      if (this.countRiskData && this.currentTab !== 1) {
        const array = []
        array.push(this.countRiskData.numCritical)
        array.push(this.countRiskData.numHigh)
        array.push(this.countRiskData.numModerate)
        array.push(this.countRiskData.numLow)

        // if (array[0] === 0 && array[1] === 0 && array[2] === 0 && array[3] === 0) {
        // }
        return [{ data: array, name: "Vulns" }]
      }
      if (this.currentTab === 1) {
        const array = []
        if (this.phasesData.length !== 0) {
          const phase = this.phasesData.filter(phase => phase.name === this.selectedTypePhase)[0]
          array.push(phase.numCritical)
          array.push(phase.numHigh)
          array.push(phase.numModerate)
          array.push(phase.numLow)
        } else {
          array.push(0, 0, 0, 0)
        }
        return [{ data: array, name: "Vulns" }]
      }
    },
    setState(value, num) {
      if (this.pentestData.status === value) {
        if (num < this.setPentestStatus(this.pentestData.status).num) {
          return 2
        } else if (num > this.setPentestStatus(this.pentestData.status).num) {
          return 0
        }
      }
    },
    setPentestStatus(type) {
      if (type === "" || type === undefined) {
        return {
          text: "N/A",
          value: "",
          state: 0,
          icon: "mdi-progress-question"
        }
      } else {
        return this.allStatus.filter(el => el.value === type)[0]
      }
    },
    setProgressColor() {
      if (this.pentestData.status === "") {
        this.progreStatus = 0
        return "transparent"
      }
      if (this.pentestData.status === "pending") {
        this.progreStatus = 1
        return "primary"
      }
      if (this.pentestData.status === "Created") {
        this.progreStatus = 1
        return "primary"
      }
      if (this.pentestData.status === "scheduled") {
        this.progreStatus = 2
        return "warning"
      }
      if (this.pentestData.status === "Ready to Start") {
        this.progreStatus = 2
        return "warning"
      }
      if (this.pentestData.status === "Testing") {
        this.progreStatus = 3
        return "warning"
      }
      if (this.pentestData.status === "in progress") {
        this.progreStatus = 4
        return "warning"
      }
      if (this.pentestData.status === "Reporting") {
        this.progreStatus = 4
        return "warning"
      }
      if (this.pentestData.status === "in redaction") {
        this.progreStatus = 5
        return "purple"
      }
      if (this.pentestData.status === "Report Ready") {
        this.progreStatus = 5
        return "purple"
      }
      if (this.pentestData.status === "Report Sent") {
        this.progreStatus = 6
        return "purple"
      }
      if (this.pentestData.status === "completed") {
        this.progreStatus = 7
        return "success"
      }
      if (this.pentestData.status === "Done") {
        this.progreStatus = 7
        return "success"
      }
    },
    displayVulnerability(vulnIndex) {
      this.vulnIndex = vulnIndex
      this.currentTab = this.pentestTabs.indexOf("Vulnerability detail")
    },
    sendEmail() {
      this.$router.push(`/send-email?pentest=${this.$route.params.id}`)
    },
    retestable() {
      if (this.lastRetestData !== null) {
        this.isRetestable = this.lastRetestData.status !== "completed"
      } else {
        this.isRetestable = this.pentestData.status !== "completed"
      }
    },
    async downloadFileData(id) {
      this.fileDataD = await FilesService.getFileById(id)
    },
    async initializeAssetData(assetId) {
      this.assetData = await AssetService.getByIdAsset(assetId)
    },
    async initializeFileData() {
      const resFiles = await FilesService.getFileByPentestId(
        this.$route.params.id
      )
      if (resFiles) {
        this.filesData = resFiles
        this.showFD = true
      }
    },
    async initializeRelatedData() {
      const response = await PentestService.getPentests(
        1, 100, null, { assetID: this.assetIdF }
      )
      const newArrayData = []
      for (let i = 0; i < response.results.length; i++) {
        if (response.results[i].id === this.$route.params.id) {
        } else {
          newArrayData.push(response.results[i])
        }
      }
      this.RelatedData = newArrayData
    },
    async initializeActivitiesData() {
      const res = await PentestService.getActivity(this.$route.params.id)
      this.Activities = res.results
      this.skeletonLoaderActivities = false
    },
    async initializeData() {
      const resPentest = await PentestService.getByIdPentests(
        this.$route.params.id
      )
      if (resPentest.assetId !== null) {
        this.assetIdF = resPentest.assetId
        await this.initializeAssetData(resPentest.assetId)
        await this.initializeRelatedData()
      }
      await this.initializeActivitiesData()
      await this.initializeFileData()

      if (resPentest) {
        this.pentestData = resPentest
        this.countRiskData = resPentest.data
        this.vulnerabilitiesData = resPentest.data.phases
        this.executiveSummaryData = resPentest.data.executiveSummary
        this.lastRetestData = resPentest.lastRetest
        this.retestData = resPentest.retests
        this.phasesData = resPentest.data.phases
        this.phaseNames = resPentest.phaseNames

        if (this.phasesData.length !== 0) {
          this.selectedTypePhase = this.phasesData[0].name
        }

        this.showEPD = true
        this.showV = true
        this.isShowExSu = true
        this.awaitToShow = true
        await this.$store.dispatch("changeThemeLoadingState", false)
      }
    },
    sendComment() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading("Sending ...")
        PentestService.postActivity(this.$route.params.id, {
          message: this.comment
        }).then(() => {
          snackbarPlugin.showSuccess("Your comment has been added")
          this.$refs.form.reset()
          this.initializeData()
        })
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError(
          "Oops, the field must have between 1 and 512 characters!"
        )
      }
    },
    syncWithMonday() {
      PentestService.syncOnMonday(this.$route.params.id).then((pentest) => {
        this.pentestData.isSynced = pentest.isSynced
      })
    },
    async donwloadLastReport() {
      await FilesService.getDownloadFileById(
        this.pentestData.lastReportFile.id,
        this.pentestData.lastReportFile.name,
        this.pentestData.lastReportFile.type
      )
    },
    getPhaseVulnerabilityData() {
      if (this.phasesData.length !== 0) {
        if (this.phasesData.filter(phase => phase.name === this.selectedTypePhase).length > 0) {
          return this.phasesData.filter(phase => phase.name === this.selectedTypePhase)[0].vulnerabilities
        }
      }
      return []
    },
    phaseTabText(phase) {
      if (phase === 'external') {
        return 'External'
      }
      if (phase === 'internal') {
        return 'Internal'
      }
      if (phase === 'webapp') {
        return 'Web App'
      }
      if (phase === 'mobile') {
        return 'Mobile'
      }
      if (phase === 'scada') {
        return 'Scada'
      }
      if (phase === 'iot') {
        return 'IoT'
      }
      return phase
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.v-stepper__step__step {
  height: 36px !important;
  width: 36px;
  font-size: 1.3rem;
}

.v-stepper,
.v-sheet.v-stepper:not(.v-sheet--outlined),
.v-stepper__header {
  box-shadow: none;
  background-color: transparent !important;
}

.v-stepper__step {
  padding-left: 0;
  padding-right: 0;
}
</style>
