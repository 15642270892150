<template>
  <div>
    <base-dialog
      :show="showCommunicationDialog"
      form-title="Send report to external recipients"
      text-btn2="Send"
      v-on:close="close"
      v-on:save="sendCommunication"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">Email adressses</div>
            <v-combobox
              v-model="selectedEmails"
              :items="emails"
              :rules="emailsRules"
              placeholder="Enter email addresses"
              multiple
              chips
              outlined
            />


          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import snackbarPlugin from '@/plugins/snackbar'
import CommunicationsService from '@/services/communications.service'

export default {
  name: "SendReportCommunicationDialog",
  components: { BaseDialog },
  props: {
    showCommunicationDialog: {
      type: Boolean,
      required: true
    },
    file: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      selectedEmails: [],
      emails: []
    }
  },
  computed: {
    emailsRules() {
      return [
        value => value.length > 0 || 'This field is required',
        this.selectedEmails.every(email => /^[^\s@]+@[^\s@]+\.[a-zA-Z]+/.test(email)) || "Invalid email addresses"
      ]
    }
  },
  methods: {
    close() {
      this.emails = []
      this.selectedEmails = []
      this.$emit('update:show-communication-dialog', false)
    },
    async sendCommunication() {
      if (this.$refs.form.validate()) {
        CommunicationsService.sendReportCommunication({
          recipients: this.selectedEmails,
          fileId: this.file.id
        }).then(() => {
          this.$swal({
            text: "Report download link sent to the email addresses",
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-primary'
            }
          })
        })
        this.close()
      } else {
        snackbarPlugin.showError('Complete the required fields!')
      }
    }
  }
}
</script>
