<template>
  <v-dialog
    v-model="showVulnsDetailDialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-toolbar
      dark
      color="primary"
      flat
    >
      <v-toolbar-title>
        <v-icon
          large
          color="white"
          class="text-36 mr-4"
        >mdi-bug-outline</v-icon>
        {{ vulns.title }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          icon
          dark
          @click.native="close"
          @keyup="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card
      fluid
      tile
      class="overflow-hidden"
    >
      <v-row class="justify-center">
        <v-col cols="12" lg="8" xl="6">
          <v-card class="pa-10" flat outlined elevation="0">
            <!--<v-row>
              <v-col cols="3" class="pa-0">
                <img
                  v-if="!$vuetify.theme.dark"
                  src="@/assets/images/logos/logo-vumetric.svg"
                >
                <img
                  v-if="$vuetify.theme.dark"
                  src="@/assets/images/logos/logo-vumetric-telus-white.svg"
                >
              </v-col>
              <v-col>
                <v-card
                  class="text-right pa-1 pr-2 white&#45;&#45;text font-weight-bold"
                  color="primary"
                  elevation="0"
                  tile
                >
                  <span>{{ pentestData.title }}</span>
                </v-card>
              </v-col>
            </v-row>-->
            <v-row>
              <v-col cols="9">
                <div class="d-flex mb-3">
                  <h3>{{ vulns.title }}</h3>
                </div>
              </v-col>
              <v-spacer />
              <v-col cols="3" sm="3" md="2">
                <risk-level :risk="vulns.risk" :lang="language" />
              </v-col>
            </v-row>
            <v-divider class="mt-n6 mb-4" />
            <v-row>
              <v-col>
                <h6 v-if="showEnvironment">
                  <span v-if="language === 'en'">Environment</span>
                  <span v-if="language === 'fr'">Environnement</span>
                </h6>
                <span v-for="(item, i) in vulns.hosts" :key="i">
                  <div>
                    {{ item.ip }}
                    <span v-if="language === 'en'"> ({{ item.hostnames[0] ? item.hostnames[0]: 'No hostname reported' }})</span>
                    <span v-if="language === 'fr'"> ({{ item.hostnames[0] ? item.hostnames[0]: "Aucun nom d'hôte signalé" }})</span>
                  </div>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <h6 v-if="vulns.description">Description</h6>
                <div class="my-0 text-justify newlineStringStyle" v-html="compiledMarkdownD" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h6 v-if="showTitleEvidence">
                  <span v-if="language === 'en'">Evidences</span>
                  <span v-if="language === 'fr'">Preuves</span>
                </h6>
                <span v-for="(evidence, index) in vulns.evidences" :key="index">
                  <v-card
                    v-if="evidence.type.includes('table')"
                    class="my-6"
                    flat
                    outlined
                  >
                    <v-data-table
                      :headers="generateHeaderTable(evidence.colNumber, evidence)"
                      :items="evidence.content"
                      hide-default-footer
                    />
                  </v-card>
                  <div
                    v-if="evidence.type === 'text'"
                    class="my-4"
                  >
                    <div class="text-justify newlineStringStyle" v-html="compiledMarkdownTextSection(evidence.content)" />
                  </div>
                  <div
                    v-if="evidence.type === 'listing'"
                    class="my-4"
                  >
                    <v-list>
                      <span v-for="(item, index2) in evidence.content" :key="index2">
                        <v-list-item><v-icon x-large>mdi-circle-small</v-icon>{{ item }}</v-list-item>
                      </span>
                    </v-list>
                  </div>
                  <div
                    v-if="evidence.type === 'request'"
                    class="my-4"
                  >
                    <v-card elevation="0" color="grey" class="pa-4">
                      <div class="primary--text text--lighten-2 font-weight-light">{{ evidence.title }}</div>
                      <div class="mt-1 newlineStringStyle">{{ evidence.content }}</div>
                    </v-card>
                  </div>
                  <div
                    v-if="evidence.type === 'image'"
                    class="my-4"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-card
                          elevation="0"
                          color="grey"
                        >
                          <v-img
                            :src="evidence.image"
                            max-width="100%"
                            alt="Image"
                          />
                          <v-card-title class="title" style="font-size: 16px !important;">{{ evidence.title }}</v-card-title>
                          <v-card-subtitle class="newlineStringStyle">{{ evidence.caption }}</v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                  <v-card
                    v-if="evidence.type === 'headers'"
                    class="my-4"
                    flat
                    outlined
                  >
                    <v-data-table
                      :headers="headers"
                      :items="evidence.content"
                      hide-default-footer
                      color="grey"
                    >
                      <template v-slot:item.stt="{ item }">
                        <v-tooltip v-if="item.stt === true" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-check
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Configured</span>
                          <span v-if="language === 'fr'">Configuré</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Not Configured</span>
                          <span v-if="language === 'fr'">Non Configuré</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.xfo="{ item }">
                        <v-tooltip v-if="item.xfo === true" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-check
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Configured</span>
                          <span v-if="language === 'fr'">Configuré</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Not Configured</span>
                          <span v-if="language === 'fr'">Non Configuré</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.xxp="{ item }">
                        <v-tooltip v-if="item.xxp === true" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-check
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Configured</span>
                          <span v-if="language === 'fr'">Configuré</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Not Configured</span>
                          <span v-if="language === 'fr'">Non Configuré</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.xcto="{ item }">
                        <v-tooltip v-if="item.xcto === true" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-check
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Configured</span>
                          <span v-if="language === 'fr'">Configuré</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Not Configured</span>
                          <span v-if="language === 'fr'">Non Configuré</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.csp="{ item }">
                        <v-tooltip v-if="item.csp === true" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-check
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Configured</span>
                          <span v-if="language === 'fr'">Configuré</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span v-if="language === 'en'">Not Configured</span>
                          <span v-if="language === 'fr'">Non Configuré</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card>
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <h6 v-if="vulns.impact">Impact</h6>
                <div class="my-2 newlineStringStyle" v-html="compiledMarkdownI" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <h6 v-if="vulns.recommendation">
                  <span v-if="language === 'en'">Recommendation</span>
                  <span v-if="language === 'fr'">Recommandation</span>
                </h6>
                <div class="my-2 newlineStringStyle" v-html="compiledMarkdownR" />
              </v-col>
            </v-row>
            <v-row v-if="vulns.references">
              <v-col class="py-0">
                <h6 v-if="language === 'en'">References</h6>
                <h6 v-if="language === 'fr'">Références</h6>
                <span v-for="(ref, index3) in vulns.references" :key="index3">
                  <ul class="my-2">
                    <li class="font-weight-bold">{{ ref.title }}</li>
                    <a :href="ref.url" target="_blank" rel="noopener">
                      {{ ref.url }}<v-icon class="ml-2 pb-1" color="primary lighten-2">mdi-open-in-new</v-icon>
                    </a>
                  </ul>
                </span>
              </v-col>
            </v-row>
            <v-divider class="d-none d-print-block" />
            <div v-if="language === 'en'" class="d-none d-print-block red--text mt-2">CONFIDENTIAL</div>
            <div v-if="language === 'fr'" class="d-none d-print-block red--text mt-2">CONFIDENTIEL</div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
// import PentestService from '@/services/pentest.service'
import { marked } from 'marked'
import RiskLevel from '@/components/base/RiskLevel.vue'

export default {
  components: { RiskLevel },
  props: {
    showVulnsDetailDialog: {
      type: Boolean,
      required: true
    },
    vulnsData: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showTitleEvidence: false,
      showEnvironment: false,
      // vulnSelectList: [],
      pentestData: {},
      headers: [
        {
          text: 'Host',
          value: 'host',
          sortable: false,
          class: 'text-left font-weight-bold subtitle-1'
        },
        {
          text: 'Strict-Transport-Security',
          value: 'stt',
          align: 'center',
          sortable: false,
          class: 'text-left font-weight-bold subtitle-1'
        },
        {
          text: 'X-Frame-Options',
          value: 'xfo',
          align: 'center',
          sortable: false,
          class: 'text-left font-weight-bold subtitle-1'
        },
        {
          text: 'X-XSS-Protection',
          value: 'xxp',
          align: 'center',
          sortable: false,
          class: 'text-left font-weight-bold subtitle-1'
        },
        {
          text: 'X-Content-Type-Options',
          value: 'xcto',
          align: 'center',
          sortable: false,
          class: 'text-left font-weight-bold subtitle-1'
        },
        {
          text: 'Content-Security-Policy',
          value: 'csp',
          align: 'center',
          sortable: false,
          class: 'text-left font-weight-bold subtitle-1'
        }
      ]
    }
  },
  computed: {
    compiledMarkdownD() {
      return marked(
        this.vulns.description ? this.vulns.description : '',
        { sanitize: true, mangle: false, headerIds: false, silent: true }
      )
    },
    compiledMarkdownI() {
      return marked(
        this.vulns.impact ? this.vulns.impact : '',
        { sanitize: true, mangle: false, headerIds: false, silent: true }
      )
    },
    compiledMarkdownR() {
      return marked(
        this.vulns.recommendation ? this.vulns.recommendation : '',
        { sanitize: true, mangle: false, headerIds: false, silent: true }
      )
    },
    vulns() {
      return this.vulnsData
    }
    // vulnsSelect: {
    //   get() {
    //     return this.vulnIndex
    //   },
    //   set(value) {
    //     this.$emit('update:vulnIndex', value)
    //     this.selectedVulns()
    //   }
    // }
  },
  mounted() {
    this.initializeData()
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  },
  methods: {
    compiledMarkdownTextSection(text) {
      return marked(text, { sanitize: true, mangle: false, headerIds: false, silent: true })
    },
    generateHeaderTable(num, data) {
      const headers = []
      for (let i = 0; i < num; i++) {
        headers.push({
          text: data[`title${i + 1}`],
          value: `col${i + 1}`,
          sortable: false,
          class: 'text-left font-weight-bold subtitle-1'
        })
      }
      return headers
    },
    close() {
      this.$emit('update:show-vulns-detail-dialog', false)
    },
    // selectVulnsP() {
    //   if (this.$route.query.v) {
    //     const vulns = parseInt(this.$route.query.v)
    //     if (vulns && vulns > 0 && vulns <= this.vulnsData.length) {
    //       this.vulnsSelect = vulns - 1
    //     } else {
    //       // this.$router.push(`/pentest/detail/${this.$route.params.id}/vulnerability`)
    //       this.$store.dispatch('changeThemeLoadingState', false)
    //     }
    //   }
    // },
    prevPage() {
      this.$router.push(`/pentest/detail/${this.$route.params.id}`)
    },
    // selectedVulns() {
    //   // this.vulns = this.vulnsData[this.vulnsSelect]
    //   this.vulnSelectList = [this.vulnsData[this.vulnsSelect]]
    // },
    initializeData() {
      this.showEnvironment = this.vulns.hosts
      this.showTitleEvidence = this.vulns.evidences
      // this.selectVulnsP()
      this.$store.dispatch('changeThemeLoadingState', false)
    },
    getRiskChipColor(risk) {
      if (risk === 'critical') {
        return 'error darken-2'
      }
      if (risk === 'high') {
        return 'error'
      }
      if (risk === 'moderate') {
        return 'warning'
      }
      if (risk === 'low') {
        return 'success'
      }
    }
  }
}
</script>

<style lang="scss">
@media print {
  .printPosition {
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}
.newlineStringStyle {
  white-space: pre-wrap;
}
strong {
  font-weight: bold;
}
code {
  background-color: transparent !important;
  text-wrap: wrap;
}
pre {
  background-color: rgba(143, 143, 143, 0.185);
  border-radius: 5px;
  padding: 4px 6px;
}
</style>
