<template>
  <div>
    <base-dialog
      :show="showEditDialog"
      form-title="Edit Pentest"
      :size="isSuperUser ? '1000px' : '900px'"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="d-flex"
        >
          <v-container v-if="progreStatus || isSuperUser" class="py-0">
            <v-col v-if="progreStatus || isSuperUser" cols="12" class="my-0 py-0">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                TITLE <span class="h6 primary--text text--lighten-2">*</span>
              </div>
              <v-text-field
                v-model="items.title"
                :rules="[v => !!v || 'This field is required', v => (v && v.length <= 128) || 'Maximum 128 characters']"
                placeholder="Enter project title"
                clearable
                outlined
                dense
              />
            </v-col>
            <v-col v-if="progreStatus || isSuperUser" cols="12" class="my-0 py-0">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                TYPE <span class="h6 primary--text text--lighten-2">*</span>
              </div>
              <v-select
                v-model="items.type"
                :items="pentestType"
                :rules="[v => !!v || 'This field is required']"
                placeholder="Select type"
                clearable
                outlined
                dense
              />
            </v-col>
            <!--<v-col v-if="isSuperUser" cols="12" class="my-0 py-0">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                STATUS <span class="h6 primary--text text--lighten-2">*</span>
              </div>
              <v-select
                v-model="items.status"
                :items="status"
                :rules="[v => !!v || 'This field is required']"
                outlined
                dense
              />
            </v-col>-->
            <v-col v-if="progreStatus || isSuperUser" cols="12" class="my-0 py-0">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                EXPECTED TIMELINE <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-menu
                v-model="menuStartDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="items.startDate"
                    label="Start date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    dense
                    hide-details
                    outlined
                    class="mb-5"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="items.startDate"
                  @input="menuStartDate = false"
                  :min="minDate"
                />
              </v-menu>
              <v-menu
                v-model="menuEndDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="items.endDate"
                    label="End date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    clearable
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="items.endDate"
                  :min="minDateEnd"
                  @input="menuEndDate = false"
                />
              </v-menu>
            </v-col>
            <!--<v-col cols="12"class="my-0 py-0">
              <div class="primary--text font-weight-bold mb-1">TECHNOLOGY</div>
              <v-combobox
                v-model="items.technos"
                :items="assetTechnos"
                chips
                multiple
                outlined
                clearable
                dense
              />
            </v-col>-->
            <!-- <v-col cols="12" class="my-0 py-0">
              <div class="primary--text font-weight-bold mb-1">LANGUAGE <span class="h6 primary--text">*</span></div>
              <v-select
                v-model="items.language"
                :items="languages"
                :rules="[v => !!v || 'This field is required']"
                outlined
                dense
              />
            </v-col> -->
          </v-container>
          <v-container class="py-0">
            <!--<v-col v-if="progreStatus || isSuperUser" cols="12" class="my-0 py-0">
              <div class="primary--text font-weight-bold mb-1">TARGETS <span class="h6 primary--text">*</span></div>
              <v-textarea
                v-model="items.scope"
                :rules="[v => !!v || 'This field is required']"
                placeholder="Enter your targets, Ex: 127.0.0.1"
                outlined
                clearable
                no-resize
                rows="5"
                dense
              />
            </v-col>-->
            <v-col cols="12" class="my-0 py-0">
              <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                SCOPE OF WORK <small class="font-italic font-weight-thin">(Optional)</small>
              </div>
              <v-textarea
                v-model="items.scopeOfWork"
                placeholder="Add any precision or specific requirements"
                outlined
                clearable
                hide-details
                no-resize
                rows="10"
                dense
              />
            </v-col>
            <!-- <v-col v-if="progreStatus || isSuperUser" cols="12" sm="12" md="12" class="my-0 py-0">
              <div class="primary--text font-weight-bold mb-1">PUBLIC ACCESS</div>
              <v-switch
                v-model="items.targetIsPublic"
                :label="items.targetIsPublic ? 'YES' : 'NO'"
                inset
                dense
              />
            </v-col> -->
          </v-container>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import PentestService from '@/services/pentest.service'
import snackbarPlugin from '@/plugins/snackbar'
import { pentestType, status, assetTechnos, languages } from '@/data/common'
import UserService from '@/services/user.service'

export default {
  components: {
    BaseDialog
  },
  props: {
    progreStatus: Boolean,
    showEditDialog: Boolean,
    items: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      valid: true,
      menuStartDate: false,
      menuEndDate: false,
      targetData: [],
      pentestType,
      status,
      assetTechnos,
      languages,
      isSuperUser: false
    }
  },
  computed: {
    minDate() {
      const today = new Date()
      return this.formatDate(today)
    },
    minDateEnd() {
      const today = new Date(Date.now() + 691200000)
      return this.formatDate(today)
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
    // this.formatBaseText()
  },
  methods: {
    formatDate(date) {
      let month = `${date.getMonth() + 1}`
      let day = `${date.getDate()}`
      const year = date.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    formatBaseText() {
      const regexTarget = this.items.scope
      regexTarget.forEach(item => {
        const targets = item.split(",")
        targets.forEach(target => {
          this.targetData += `${target}\n`
        })
      })
    },
    formatTarget() {
      if (this.targetData === null || this.targetData <= 0) {
        this.items.scope = null
      } else {
        const regexTarget = this.targetData
        const data = [];
        const targets = regexTarget.split("\n")
        targets.forEach(target => {
          target = target.trim();
          if (target !== "") {
            data.push(target);
          }
        });
        this.items.scope = data
      }
    },
    close() {
      this.$emit('update:show-edit-dialog', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Updating ...')
        PentestService.putPentest(this.$route.params.id, this.items)
          .then(() => {
            snackbarPlugin.showSuccess(`Pentest ${this.items.title} updated!`)
            this.$emit('reload-items')
          })
        this.close()
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
